/**
 * Service to manage API calls for table data
 * Created Date : 20-06-2024
 */
import axios from './axiosService.js';

//  Local mock data imports
import tableData from '../assets/data/tables.json';
import autoSuggestedTableData from '../assets/data/autoSuggestedTableDetails.json';
import lineAnalysisData from '../assets/data/lineAnalysisDetails.json';
import likeData from '../assets/data/likeData.json';
import additionalContextsData from '../assets/data/additionalContexts.json';

import CommonService from './commonService.js';

class TableService {

  commonService = new CommonService();
  
  constructor() {
    this.apiPointer = process.env.REACT_APP_PLATFORM_API_POINTER;
    this.apiFolder = process.env.REACT_APP_PLATFORM_API_FOLDER;
    this.apiAnalysisFolder = process.env.REACT_APP_PLATFORM_API_ANALYSIS_FOLDER;

    //  Headers
    this.headers = { "Content-type": "application/json; charset=UTF-8" };

    //  API url methods
    this.apiUrl = {
      getTables: '/projects/<vProjectId>/tables',
      addTable: '/projects/tablesupload',
      editTable: '/editTable',
      deleteTable: '/deleteTable',
      getAutoSuggestedTableDetails: '/projects/table/autofillMetadata',
      addTableRelationship: '/projects/<vProjectId>/relationships?tableId=',
      getLineAnalysisDetails: '/fetch_response',
      submitFeedback: '/feedback',
      getTableRelationship:'/listRelationship',
      getLineItemsState: '/includeExcludeItemsLineAnalysis',
      requestExportAndDownloadUrl: '/export_qna',
      deleteTableRelationship: '/deleteRelationship',
      exportImportDetailsUrl: '/get_export_details',
      getAdditionalContext: "/ViewAddnContext",
      addAdditionalContext: "/additional_context/create",
      deleteADC: "/DeleteAddnContext",
      clearLineAnalysisUrl: "/ClearLineAnalysis",
      lineCommAddToExcel: "/fetch_response_linecomm"
    }

    //  List of API endpoints to point to local mock data
    this.mockApiUrl = [
      // 'getLineAnalysisDetails',
      // 'getLineItemsState',
      // "getTables",
      // "getAdditionalContext",
    ];
  }

  /**
   * Function to get projectwise tables
   * @param {*} projectId
   * @returns response
   */
  async getTables(projectId) {
    if (this.apiPointer.toLowerCase() === "local" || this.mockApiUrl.includes("getTables")) {
      return Promise.resolve(tableData);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.getTables.replace("<vProjectId>", projectId);
      const vParams = {
        method: "get",
        url: vUrl,
        headers: this.headers,
        data: JSON.stringify({}),
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(error, "Table Service", "Get Tables");
      });
      return response?.data;
    }
  }

  /**
   * Function to save table
   * @param {*} vData
   * @returns
   */
  async addTable(vData) {
    if (this.apiPointer.toLowerCase() === "local") {
      return Promise.resolve(tableData);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.addTable;
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: JSON.stringify(vData),
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(error, "Table Service", "Add Table");
      });
      return response?.data;
    }
  }

  /**
   * Function to update table
   * @param {*} vData
   * @returns
   */
  async editTable(vData) {
    if (this.apiPointer.toLowerCase() === "local") {
      return Promise.resolve(tableData);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.editTable;
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: JSON.stringify(vData),
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(error, "Table Service", "Add Table");
      });
      return response;
    }
  }

    /**
   * Function to call delete table API to delete specific table
   * @param {*} vProjectId
   * @param {*} vTableId
   * @returns
   */
    async deleteTable(projectId, tableId) {
      if (this.apiPointer.toLowerCase() === "local") {
        return Promise.resolve("Deletion: 1 table");
      } else {
        const vApiEndpoint = this.commonService.getAPIUrl();
        const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.deleteTable;
        const vParams = {
          method: "post",
          url: vUrl,
          headers: this.headers,
          data: { projectId: projectId, tableId: tableId},
        };
        const response = await axios.request(vParams).catch((error) => {
          this.commonService.logException(
            error,
            "Table Service",
            "Add Table replationship"
          );
        });
        return response?.data;
      }
    }

  /**
   * Function to get autofill table details in second step
   * @param {*} vData
   * @returns
   */
  async getAutoSuggestedTableDetails(vData) {
    if (this.apiPointer.toLowerCase() === "local") {
      return Promise.resolve(autoSuggestedTableData);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.getAutoSuggestedTableDetails;
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: JSON.stringify(vData),
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Get Auto Suggested Table Details"
        );
      });
      return response?.data;
    }
  }

  /**
   * Function to call add table relationship API to save table relationship
   * @param {*} vProjectId
   * @param {*} vTableId
   * @param {*} vData
   * @returns
   */
  async addTableRelationship(vProjectId, vTableId, vData) {
    if (this.apiPointer.toLowerCase() === "local") {
      return Promise.resolve("Creation: 1 relationship");
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.addTableRelationship.replace("<vProjectId>", vProjectId) + vTableId;
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: JSON.stringify(vData),
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Add Table replationship"
        );
      });
      return response?.data;
    }
  }

  /**
   * Function to call add table relationship API to save table relationship
   * @param {*} vTableId
   * @returns
   */
  async getTableRelationship(vTableId) {
    if (this.apiPointer.toLowerCase() === "local") {
      return Promise.resolve(tableData);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.getTableRelationship;
      const getRelationshipRequest = {
        tableId: vTableId,
      };
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: getRelationshipRequest,
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Get Tables Relationship"
        );
      });
      return response?.data;
    }
  }

  /**
   * Function to call delete relationship API to delete the  relationship
   */
  async deleteTableRelationship(relationshipId) {
    if (this.apiPointer.toLowerCase() === "local") {
      return Promise.resolve({ message: "Relationship Deleted Sucessfully" });
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.deleteTableRelationship;
      const deleteTableRelationshipRequest = {
        relationship_id: relationshipId,
      };
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: deleteTableRelationshipRequest,
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Delete Tables Relationship"
        );
      });
      return response?.data;
    }
  }

  /**
   * This method deletes KPIs/Metrics.
   */
  async deleteADC(projectId, tableId, kpIds) {
    const vApiEndpoint = this.commonService.getAPIUrl();
    const url = vApiEndpoint + this.apiFolder + this.apiUrl.deleteADC;
    const params = {
        method: "post",
        url: url,
        headers: this.headers,
        data: { projectId: projectId, tableId: tableId, kpIds: [kpIds] },
      };
      const response = await axios.request(params).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Delete KPIs/Metrics"
        );
      });
      return response?.status;
  }

  /**
   * Function to get Line Analysis details
   * @param {*} vData
   * @returns
   */
  async getLineAnalysisDetails(vData) {
    if (this.apiPointer.toLowerCase() === "local" || this.mockApiUrl.includes("getLineAnalysisDetails")) {
      return Promise.resolve(lineAnalysisData);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiAnalysisFolder + this.apiUrl.getLineAnalysisDetails;
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: JSON.stringify(vData),
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Get Line Analysis Details"
        );
      });
      return response?.data;
    }
  }

  /**
   * Function to submit feedback as like or dislike line commentary
   * @param {*} vData
   * @returns
   */
  async submitFeedback(vData) {
    if (this.apiPointer.toLowerCase() === "local") {
      return Promise.resolve(likeData);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.submitFeedback;
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: JSON.stringify(vData),
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Submit feedback"
        );
      });
      return response?.data;
    }
  }

  /**
   * Function to clear line analysis for selected lineitems
   * @param {*} cLData
   * @returns
   */
  async clearLineAnalysis(cLData) {
      const cApiEndpoint = this.commonService.getAPIUrl();
      const cUrl = cApiEndpoint + this.apiFolder + this.apiUrl.clearLineAnalysisUrl;
      const cParams = {
        method: "post",
        url: cUrl,
        headers: this.headers,
        data: JSON.stringify(cLData),
      };
      const response = await axios.request(cParams).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Clear Line Analysis State"
        );
      });
      return response?.data;
  }

  /**
   * Function to get line commentary details
   * @param {*} LineCommentaryRowData 
   * @returns 
   */
  async lineCommToExcel(projectId,tableId,LineCommentaryRowData,userId ) {
    const lineCommApiEndpoint = this.commonService.getAPIUrl();
      const lineCommUrl = lineCommApiEndpoint + this.apiAnalysisFolder + this.apiUrl.lineCommAddToExcel;
      const cParams = {
        method: "post",
        url: lineCommUrl,
        headers: this.headers,
        data: {projectId: projectId, tableId: tableId, rowId: LineCommentaryRowData, userId: userId},
      };
      const response = await axios.request(cParams).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Line Commentary Add to Excel"
        );
      });
      return response?.data;
  }

  async requestExportAndGetDownloadURL(data) {
    const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiAnalysisFolder + this.apiUrl.requestExportAndDownloadUrl;
      const params = {
          method: "post",
          url: vUrl,
          headers: this.headers,
          responseType: 'blob',
          data: data,
      };
      const response = await axios.request(params).catch((error) => {
          this.commonService.logException(
              error,
              "Table Service",
              "Request Export and Get DownloadURL"
          );
      });
      return response;
  }

  async getExportImportDetails(data) {
    const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiAnalysisFolder + this.apiUrl.exportImportDetailsUrl;
      const params = {
          method: "post",
          url: vUrl,
          headers: this.headers,
          data: data,
      };
      const response = await axios.request(params).catch((error) => {
          this.commonService.logException(
              error,
              "Table Service",
              "Get export import details"
          );
      });
      return response?.data;
  }

  /**
   * Function to get KPIs/Metrics
   * @param {*} vTableId
   * @returns
   */
  async getAdditionalContext(vData) {
    if (
      this.apiPointer.toLowerCase() === "local" ||
      this.mockApiUrl.includes("getAdditionalContext")
    ) {
      return Promise.resolve(additionalContextsData);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl =
        vApiEndpoint + this.apiFolder + this.apiUrl.getAdditionalContext;
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: JSON.stringify(vData),
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Get KPIs/Metrics"
        );
      });
      return response?.data;
    }
  }

  /**
   * Function to add KPIs/Metrics
   * @param {*} vData 
   * @returns 
   */
  async addAdditionalContext(vData) {
    if (this.apiPointer.toLowerCase() === "local") {
      return Promise.resolve(tableData);
    } else {
      const vApiEndpoint = this.commonService.getAPIUrl();
      const vUrl = vApiEndpoint + this.apiFolder + this.apiUrl.addAdditionalContext;
      const vParams = {
        method: "post",
        url: vUrl,
        headers: this.headers,
        data: JSON.stringify(vData),
      };
      const response = await axios.request(vParams).catch((error) => {
        this.commonService.logException(
          error,
          "Table Service",
          "Add KPIs/Metrics"
        );
      });
      return response?.data;
    }
  }
}

export default TableService;