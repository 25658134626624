import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import ThresholdService from '../../../services/thresholdService';

//  Motif imports 
import MotifTextLink from '@ey-xd/motif-react/TextLink';
import { MotifIconButton } from '@ey-xd/motif-react/Button';
import MotifTooltip from '@ey-xd/motif-react/Tooltip';

/**
 * Internal imports
 */
import './Projects.scss';
import NoProjects from "../noProjects/NoProjects";
import { setActiveProject, setProjectThresholds } from "../../../auth/userSlice";
import { groupConfig } from "../../../config/groupConfig";
import ProjectService from "../../../services/projectService";
import Utils from "../../../utils/Utils";

//  Icons
import iconInfo from '../../../../src/assets/images/info.svg';

export default function Projects() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const thresholdService = new ThresholdService();
    const { user, projects, userGroupNamePlatform, platformAdminUsers, userGroupName } = useSelector(state => state.user);
    // Local state to manage projects with the new column
    const [thresholdProjects, setThresholdProjects] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const projectService = new ProjectService();
    let projectThresholdList = [];
    const utils = new Utils();

    useEffect(() => {
        if (userGroupNamePlatform !== null && userGroupNamePlatform === groupConfig.groupNamePlatForm) {
            setIsAdmin(true);            
        }
        else {
            setIsAdmin(false);
        }        
        // eslint-disable-next-line
    }, [userGroupNamePlatform]);

    useEffect(() => {
        const fetchAndUpdate = async () => {
            if (projects && projects.length > 0) {
                // Update the local state with the new column
                await getProjectsThreshold();
                if (projectThresholdList.length > 0) {
                    const vThresholdProjects = addThresholdValue(projects);
                    setThresholdProjects(vThresholdProjects);
                    
                    let finalProjects = vThresholdProjects; 
                    // Sort the final projects by createTS in descending order
                    finalProjects.sort((a, b) => new Date(b.createTS) - new Date(a.createTS));

                    dispatch(setProjectThresholds({
                        thresholdProjects: finalProjects
                    }));
                    setThresholdProjects(finalProjects);
                } else {
                    setThresholdProjects(projects);
                }
            } else {
                setThresholdProjects(projects);
            }            
        }
        fetchAndUpdate();
        // eslint-disable-next-line
    }, [projects, isAdmin, platformAdminUsers]);

    const getProjectsThreshold = async () => {
        const thresholdProjects = await projectService.GetProjectThresholdList(user?.username);
        if (thresholdProjects?.length > 0) {
            projectThresholdList = thresholdProjects;
        }
    }


    // Function to add thresholdValue
    const addThresholdValue = (projects) => {
        return projects.map(project => {
            // Find the threshold item that matches the project's UUID
            const thresholdItem = projectThresholdList.find(
                item => item.id === project.project_UUID
            );

            let thresholdValue = 'No';
            let globalThresholdValue;
            let globalThresholdPercent;

            if (thresholdItem) {
                if (thresholdItem.hasOwnProperty('globalThresholdValue') && thresholdItem.hasOwnProperty('globalThresholdPercent')) {
                    if (thresholdItem.globalThresholdValue >= 0 && thresholdItem.globalThresholdPercent >= 0) {
                        globalThresholdValue = thresholdItem.globalThresholdValue;
                        globalThresholdPercent = thresholdItem.globalThresholdPercent;
                        thresholdValue = 'Yes';
                    }
                }
            }

            return {
                ...project,
                thresholdValue,
                globalThresholdValue,
                globalThresholdPercent
            };
        });
    };


    const navToThreshold = (projectId, projectName) => {
        navigate('/threshold');
    }

    const navToProject = (projectId, projectName) => {
        navigate('/projectdetails');
    }

    const handleProjectNameClick = async (project) => {
        dispatch(setActiveProject({
            activeProject: project
        }));
        const userId = user?.username;
        const userRole = userGroupName;
        const response = await thresholdService.getThresholdForProject(project.project_UUID, userId, userRole);
        if (response && !response.threshold_set) {
            navToProject(project.project_UUID, project.projectName);
        } else if (response && response.threshold_set) {
            navToThreshold(project.project_UUID, project.projectName);
        }
    }

    return (
        (() => {
            if (thresholdProjects && thresholdProjects.length !== 0) {
                return thresholdProjects.map((project, index) => {
                    return (
                        <div className={index !== 0 ? "project-div" : "project-div no-border"} key={project.project_UUID}>
                            <div className={((project.role==="Admin" || project.createdBy===user?.username)?"project-inner-div": (project.thresholdValue !== "Yes" ? "is-disabled-external" : "project-inner-div"))}>
                                <div className={(project.role==="Admin" || project.createdBy===user?.username ? "":(project.thresholdValue !== "Yes" ? "is-disabled" : ""))}>
                                    <div className="project-name">
                                        <MotifTextLink onClick={() => handleProjectNameClick(project)} href="#">
                                            {project.projectName}
                                        </MotifTextLink>
                                    </div>
                                    <span className="project-date">{'Created on: ' + utils.formatDateString(project.createTS, 'Mmm DD, YYYY')}</span>
                                </div>
                                {(project.createdBy !== user.username) && (project.role !== "Admin") && (project.thresholdValue !== "Yes") && (
                                    <MotifIconButton
                                        aria-label="Info"
                                        id="no-threshold-icon"
                                        className="iconNoThreshold"
                                    >
                                        <MotifTooltip
                                            trigger={
                                                <img src={iconInfo} alt='No Threshold Icon' /> 
                                            }
                                            hideCloseButton={true}
                                            contentClassName="tooltipWithoutCloseCenterText"
                                            allowClick={false}
                                        >
                                            <div>
                                                Global thresholds not configured. Contact a <br></br>Project Admin to set
                                                Global thresholds and<br></br>enable access to the project.
                                            </div>
                                        </MotifTooltip>
                                    </MotifIconButton>
                                )}
                            </div>
                        </div>
                    )
                })
            } else {
                return <NoProjects />
            }
        })()
    )
}
