/**
 * Clear Line Analysis
 * Created On: 09-12-2024
 */

import MotifModal, { MotifModalHeader, MotifModalBody } from '@ey-xd/motif-react/Modal';
import { MotifModalFooter } from "@ey-xd/motif-react";
import MotifButton from '@ey-xd/motif-react/Button';
import React from "react";
import warningCircle from '../../../../assets/images/warning.svg';
import "./ClearLineAnalysis.scss"


const ClearLineAnalysis = ({ onClose, handleClearAnalysis, checkedRowHeader }) => {
    return (
        <div>
        <MotifModal id="clearAnalysisModal" show={true}

            focusTrapOptions={{
                tabbableOptions: {
                    displayCheck: 'none',
                },
            }}
            onClose={onClose}
            className='clearAnalysisModal genericModal modalNoHeaderBorder startIconClose modalMotifFooterModal'
        >
            <MotifModalHeader
                className='clearAnalysisHeader'
                closeButtonProps={{
                    'aria-label': 'Close',
                    title: 'Close'
                }}
            >
                <div className='closeSymbolContainer'>
                    <img className='warningCircle' alt='icon' src={warningCircle} />
                </div>
                <div className='clearTextHeading'>Clear Analysis</div>
            </MotifModalHeader>
            <MotifModalBody className='clearAnalysisBody'>
                <React.Fragment key=".0">

                    <div className='clearAnalysisTextLines'>
                        <span >
                        The analysis will be cleared for selected rows along with Table Commentary. This action is irreversible. Click ‘Confirm’ to apply.
                        </span>
                    </div>
                    <div className='clearListHeaderNames'>
                        <ul style={{ listStyleType: "disclosure-closed" }} className="li-clearanalysis-ul">
                        {checkedRowHeader?.length > 0 &&
                            checkedRowHeader?.map((header) => {
                            return (
                                <li className="li-clear-bullet">
                                {" "}
                                {header}{" "}
                                </li>
                            );
                            })}
                        </ul>
                    </div>                    
                </React.Fragment>
            </MotifModalBody>
            <MotifModalFooter className='clearAnalysisFooter'>
                <React.Fragment key=".0">
                    <MotifButton
                        className='btnCancel '
                        label="btnCancel"
                        size="small"
                        type="button"
                        variant="secondary"
                        onClick={onClose}
                    >
                        Cancel
                    </MotifButton>
                    <MotifButton
                        label="btnConfirm"
                        size="small"
                        type="button"
                        variant="primary"
                        className='btnConfirm'
                        onClick={handleClearAnalysis}
                    >
                        Confirm
                    </MotifButton>
                </React.Fragment>
            </MotifModalFooter>
        </MotifModal>
    </div>
    );
};
export default ClearLineAnalysis;